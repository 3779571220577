































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BlogFilter } from 'client-website-ts-library/filters'
import { WebsiteLevel, BlogType, Blog } from 'client-website-ts-library/types'
import { API, Config } from 'client-website-ts-library/services'
import NavigationLink from './NavigationLink.vue'

@Component({
  components: {
    NavigationLink,
  },
})
export default class Header extends Vue {
  @Prop()
  public readonly mobileNavActive!: boolean

  @Prop()
  public readonly isStuck!: boolean

  public suburbsFilter = new BlogFilter({
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.API.WebsiteId,
    Types: [BlogType.Suburb],
  });

  public suburbs: Blog[] = [];

  mounted() {
    API.Blogs.Search(this.suburbsFilter).then((data) => {
      this.suburbs = data.sort((a, b) => a.Title.localeCompare(b.Title))
    })
  }
}
