











import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class NavigationLink extends Vue {
  @Prop()
  readonly to!: string

  @Prop({ default: false })
  readonly newPage!: boolean

  @Prop()
  readonly external!: boolean

  @Prop()
  readonly skewed!: boolean

  handleClicked(e: Event) {
    e.preventDefault()

    this.$emit('click')

    if (this.external) {
      window.open(this.to, '_blank')
    } else if (this.$route.path !== this.to) {
      if (this.newPage) {
        window.open(this.to, '_self')
      } else {
        this.$router.push(this.to)
      }
    }
  }
}
