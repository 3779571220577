
















































































import { Component, Vue } from 'vue-property-decorator';
import { Office, WebsiteLevel, BlogType, Blog } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import GMap from '@/components/Map/GMap.vue';

import { BlogFilter } from 'client-website-ts-library/filters';
import ContentRow from '../Layout/ContentRow.vue';
import ContentContainer from '../Layout/ContentContainer.vue';

@Component({
  components: {
    GMap,
  },
})
export default class Footer extends Vue {
  public office: Office | null = null;

  public suburbsFilter = new BlogFilter({
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.API.WebsiteId,
    Types: [BlogType.Suburb],
  });

  public suburbs: Blog[] = [];

  public mapAddress: any | null = 'Freemans Residential Cairns QLD 4870';

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
      // this.mapAddress = `${this.office.AddressLine1} ${this.office.AddressLine2}`;
    });

    API.Blogs.Search(this.suburbsFilter).then((data) => {
      this.suburbs = data.sort((a, b) => a.Title.localeCompare(b.Title));
    });
  }

  // eslint-disable-next-line
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  // eslint-disable-next-line
  get year(): string {
    return new Date().getFullYear().toString();
  }

  get hideMap(): boolean {
    return this.$route.meta?.hideOfficeMap;
  }
}
